<template>
  <div
    v-if="isShow"
    class="main_bg"
    style="color:#fff"
  >
    <div class="m_top_area">
      <div
        class="left"
        style="margin-top:-24px;padding:10px"
      >
        <a
          id="menu_btn"
          href="javascript:void(0)"
          title="메뉴"
          class="btn_top_menu"
        >
          <span class="ir">메뉴</span>
        </a>
      </div>
      <div class="right">
        <router-link
          to="/ContentsList?cate_a=search"
          class="btn_top_sch"
          style="margin:-14px 10px 10px"
          title="검색으로이동"
        >
          <span class="ir">검색</span>
        </router-link>
      </div>
    </div>

    <div class="flex_scroll">
      <div
        class="main_title"
        style="position:relative"
      >
        XRMagic
      </div>
      <div class="cate_a_ctnr">
        <div
          v-for="el in listCateA"
          :key="el.cate"
          class="cate_a"
          @click.stop="onClickCateA(el.cate)"
        >
          <div>
            <img src="/img/ico/ic_collection_folder.png">
            <img
              :src="`/img/img/${el.icon ? el.icon : `${el.cate}.jpg`}`"
              @error="function(ev){ev.target.src='/img/img/cate_a/default.jpg'}"
            >
            <div>
              {{ getLang(el.nm) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu_footer">
      <img
        src="/img/img/logo_01.png"
        style="width:100px"
      >
    </div>

    <Menu id="drawer" />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import Menu from '@/components/Menu.vue';

export default {
  metaInfo () {
    return {
      title: this.title
    };
  },
  components: {
    Menu,
  },
  data () {
    return {
      title: 'HoloMagic',
      isShow: false,
      listCateA: [],
      protocol: location.protocol,
      test_txt: '', // window.navigator.userAgent,
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang',
      nameSelectedCateA: 'getNameSelectedCateA',
      listCateB: 'getListCateB',
      mainTP: 'getMainTP',
    }),
  },
  async mounted () {
    if (process.env.NODE_ENV == 'production') try { console.clear(); } catch (e) {}

    this.clearContsListAll();
    await this.loadBasic();

    if (this.mainTP == '*') {
      this.$store.state.cateInfo.filter(el => !el.parent_uid).forEach(el => {
        this.listCateA.push({
          cate: el.cate_uid,
          nm: el.cate_name,
          icon: el.icon,
        });
      });

      this.isShow = true;
    } else {
      this.$router.replace({ path: '/ContentsList', query: { from_main: 'Y', cate_a: this.mainTP } });
    }
  },
  methods: {
    ...mapMutations({
      clearContsListAll: 'clearContsListAll',
      setSelectedCateA: 'setSelectedCateA',
    }),
    ...mapActions({
      loadBasic: 'loadBasic',
    }),
    onClickCateA (idx) {
      this.$router.push({ path: '/ContentsList', query: { cate_a: idx } });
    },
  },
};
</script>

<style scoped>
  .main_title {
    margin-top: 50px;
    text-align: center;
    font-size: 4rem;
  }
  .cate_a_ctnr {
    padding: 30px;
  }
  .cate_a {
    position: relative;
    width: 50%;
    display: inline-block;
    cursor: pointer;
  }
  .cate_a>div {
    margin: 30px;
    width: calc(100% - 60px);
    border-radius: 50px 0 50px 50px;
    overflow: hidden;
    background-color: #fff;
  }
  .cate_a>div>img:nth-child(1) {
    position: absolute;
    right: 30px;
    width: 40px;
    height: 40px;
  }
  .cate_a>div>img:nth-child(2) {
    width: 100%;
    aspect-ratio: 5;
  }
  .cate_a>div>div {
    padding: 0 15px;
    width: 100%;
    text-align: center;
    color: #000;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: 1px solid #eee;
  }

  @media screen and (max-width: 1000px) {
    .main_title {
      margin-top: 15px;
    }
    .cate_a>div {
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 20px 0 20px 20px;
    }
    .cate_a>div>img:nth-child(1) {
      right: 10px;
    }
  }
  @media screen and (max-width: 800px) {
    .main_title {
      margin-top: 50px;
    }
    .cate_a {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .cate_a_ctnr {
      padding: 20px 0;
    }
    .cate_a>div {
      border-radius: 10px 0 10px 10px;
    }
    .cate_a>div>img:nth-child(1) {
      width: 30px;
      height: 30px;
    }
  }
</style>
