<template>
  <div class="slide_menu drawer">
    <div class="menu_title">
      {{ getLang('기능') }}
    </div>
    <ul class="menu_list">
      <li>
        <a
          href="javascript:void(0)"
          @click="onMenuClick('collection')"
        >
          <i class="bu_3" />{{ getLang('즐겨찾기') }}
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="onMenuClick('download')"
        >
          <i class="bu_7" />{{ getLang('3DBANK에서 다운로드') }}
        </a>
      </li>
      <!-- <li>
        <a
          href="javascript:void(0)"
          @click="onMenuClick('upload_3d')"
        >
          <i class="bu_7" />{{ getLang('3D 모델 업로드') }}
        </a>
      </li> -->
      <div style="margin:10px 10px; width:80%; height:1px; border-bottom:1px solid #555" />
      <li>
        <a
          href="javascript:void(0)"
          @click="onMenuClick('setting')"
        >
          <i class="setting" />{{ getLang('설정') }}
        </a>
      </li>
    </ul>
    <div
      class="menu_footer"
      style="position:absolute; bottom:0; cursor:pointer"
      @click="logout"
    >
      로그아웃
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      drawer: null,
      isSSL: location.protocol == 'https:',
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang'
    }),
    stateNetwork () {
      return this.$store.state.stateNetwork;
    },
    isAutoAnnounce () {
      return this.$store.state.isAutoAnnounce;
    },
  },
  beforeDestroy () {
    this.drawer.removeListener();
  },
  mounted () {
    this.$nextTick(function () {
      var drawerElem = document.getElementById('drawer');
      var drawerButton = document.getElementById('menu_btn');
      this.drawer = new window.Drawer(drawerElem);
      this.drawer.closeDrawer();
      this.drawer.setDrawerIcon(new window.DrawerIcon(drawerButton));
      this.drawer.onOpenListener(this.handleDrawerOpen);
      this.drawer.onCloseListener(this.handleDrawerClose);
    });
  },
  methods: {
    onMenuClick (cmd, param) {
      switch (cmd) {
        case 'collection':
          this.$router.push({ path: '/FavList' });
          break;
        case 'download':
          this.$router.push({ path: '/Download' });
          break;
        case 'upload_3d':
          this.$router.push({ path: '/Upload3D' });
          break;
        case 'setting': {
          this.$router.push({ path: '/Setting' });
          break;
        }
      }
    },
    handleDrawerClose () {},
    handleDrawerOpen () {
      if (this.drawer.isOpen()) {
        var drawerElem = document.getElementById('drawer');
        if (drawerElem) {
          if (drawerElem.style.opacity != 1) {
            this.drawer.openDrawer();
          }
        }
      }
    },
    logout () {
      this.$cookies.remove('token');
      location.reload();
    },
  },
};
</script>

<style scoped>
</style>
